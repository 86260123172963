import React, { useState } from "react";
import Navbar from "../../../../../components/Navbar/Navbar";
import "../../../../../components/Navbar/navbar.css";
import Styles from "../Solucoes.module.css";
import { useQuery, gql } from "@apollo/client";
import SolucoesLogo from "./SolucoesLogo";

const Query = gql`
  query GetHeroSolucoes {
    solucoesGetEdu {
      data {
        attributes {
          SolucoesLogo {
            data {
              attributes {
                url
              }
            }
          }
          SolucoesRightPersonImage {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;
export default function Hero() {
  const { loading, error, data } = useQuery(Query);

  const [isNavExpanded, setIsNavExpanded] = useState(false);

  const baseUrl = "https://admin.getedu.tec.br/";

  return (
    <section id="hero-aguia" className={Styles.heroSolucoes}>
      <Navbar />
      <div className="heroBody">
        <div class="container">
          <div className={Styles.miniContainer}>
            <div className={Styles.header}>
              <SolucoesLogo />
            </div>

            <div id="man">
              <img
                alt="banner"
                src={
                  baseUrl +
                  data?.solucoesGetEdu.data.attributes.SolucoesRightPersonImage
                    .data.attributes.url
                }
                className={Styles.man}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
