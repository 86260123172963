import Circle from "../../../assets/capbolaSelecao.svg";
import BlueCircle from "../../../assets/capbolaSelecaoAzul.svg";
import Styles from "../../Solucoes.module.css";

export default function SolucoesLogo() {
  return (
    <div className={Styles.solucoesLogo}>
      <h3>
        "Soluções"
      </h3>
      <div className={Styles.reticencias}>
        <img src={Circle} alt="circle" />
        <img src={Circle} alt="circle" />
        <img src={BlueCircle} alt="circle" />
      </div>
    </div>
  );
}
