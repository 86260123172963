import React, { useState } from "react";
import Styles from "../Quem.module.css";
import Nuvenzinha from "../../assets/nuvenzinha.svg";
import parse from "html-react-parser";
import { useQuery, gql } from "@apollo/client";

const QUEM = gql`
  query GetQuem {
    quemSomosTela {
      data {
        attributes {
          HistoriaTitulo
          HistoriaTexto
          HistoriaImg {
            data {
              attributes {
                url
              }
            }
          }
          HistoriaImgMobile {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export default function Historia() {
  const { loading, error, data } = useQuery(QUEM);
  const baseUrl = "https://admin.getedu.tec.br/";

  console.log(data?.quemSomosTela.data.attributes.HistoriaTitulo);
  return (
    <section className={Styles.defaultSection}>
      <div className={Styles.historiaDesktop}>
        <div className={Styles.happyContainer}>
          <img
            src={
              baseUrl +
              data?.quemSomosTela.data.attributes.HistoriaImgMobile.data
                .attributes.url
            }
            className={Styles.hpWoman}
            alt=""
          />
          <img
            alt=""
            src={
              baseUrl +
              data?.quemSomosTela.data.attributes.HistoriaImg.data.attributes
                .url
            }
            className={Styles.hpWomanDesktop}
          />
        </div>

        <div className={Styles.historiaContainer}>
          <div className={Styles.firstColor}>
            <div className={Styles.secondColor}>
              <div className={Styles.storyContent}>
                <div className={Styles.nuvenzinha}>
                  <img src={Nuvenzinha} alt="" />
                </div>
                <div className={Styles.historinha}>
                  <h2>
                    {parse(
                      `${data?.quemSomosTela.data.attributes.HistoriaTitulo}`
                    )}
                  </h2>

                  {parse(
                    `${data?.quemSomosTela.data.attributes.HistoriaTexto}`
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
