import React from "react";
import Styles from "../Quem.module.css";
import SomosAssim from "../../assets/somosAssim.svg";

import { useQuery, gql } from "@apollo/client";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";

const QUEM = gql`
  query GetQuem {
    quemSomosTela {
      data {
        attributes {
          SomosAssimTexto
        }
      }
    }
  }
`;

export default function Somos() {
  const { loading, error, data } = useQuery(QUEM);

  return (
    <section className={Styles.defaultSection}>
      <div className={Styles.somosContainer}>
        <img src={SomosAssim} />
        <div className={Styles.somosDesc}>
           
            {data?.quemSomosTela.data.attributes.SomosAssimTexto}
          
        </div>
      </div>
    </section>
  );
}
