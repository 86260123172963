import React, { useState } from "react";
import InputMask from "react-input-mask";
import Google from "../../assets/google.svg";
import Divisor from "../../assets/divisor.svg";

export default function Subscribe(props) {
  const getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
      if (typeof value === "object" && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
  };

  const onFocusChange = () => {
    document.getElementById("previFechamento").type = "date";
  };

  const onBlurChange = () => {
    document.getElementById("previFechamento").type = "text";
  };

  const [formValues, setFormValues] = useState({
    id: null,
    CNPJ: null,
    razaoSocial: null,
    dominioEducacional: null,
    nomeCompleto: null,
    cargoContato: null,
    telefoneContato: null,
    emailContato: null,
    ufContato: null,
    cidadeContato: null,
    Endereco: null,
    CEP: null,
    qtdProfessores: null,
    tipoServico: null,
    qtdCEUs: null,
    qtdDashboard: null,
    previFechamento: null,
  });

  async function checkCNPJ() {
    let formCNPJ = document
      .getElementById("CNPJ")
      .value.replace(/[.,/,-]/g, "");
    let response = await fetch(
      `https://cors-anywhere-production-7aab.up.railway.app/https://receitaws.com.br/v1/cnpj/${formCNPJ}`
    );
    let jsonData = await response.json();

    document.getElementById("razaoSocial").value = jsonData.nome;
    document.getElementById("UF").value = jsonData.uf;
    document.getElementById(
      "Endereco"
    ).value = `${jsonData.logradouro}, ${jsonData.numero}, ${jsonData.bairro}`;
    document.getElementById("city").value = jsonData.municipio;
    document.getElementById("CEP").value = jsonData.cep;
  }

  async function enviarFormulario() {
    // ENDPOINT
    let endpoint =
      "https://app.pipe.run/webservice/integradorJson?hash=a484f20d-9a52-4cf3-8aad-18e52fab3856";

    // // // RULES
    let rules = {
      update: true,
      equal_pipeline: true,
      filter_status_update: "open",
    };

    // // // LEAD
    let lead = [
      {
        id: document.getElementById("CNPJ").value,
        title: document.getElementById("razaoSocial").value,
        user: document.getElementById("emailContato").value,
        name: document.getElementById("nomeCompleto").value,
        email: document.getElementById("emailContato").value,
        mobile_phone: document.getElementById("telefoneContato").value,
        last_conversion: {
          source: "Formulário site",
        },

        custom_fields: {
          CNPJ: document.getElementById("CNPJ").value,
          razao_social_oportunidade:
            document.getElementById("razaoSocial").value,
          dominio_educacional:
            document.getElementById("dominioEducacional").value,
          cargo_contato: document.getElementById("cargoContato").value,
          endereco: document.getElementById("Endereco").value,
          city_state: document.getElementById("UF").value,
          "Cidade do contato": document.getElementById("city").value,
          qtd_professores: document.getElementById("qtdProfessores").value,
          tipo_servico: document.getElementById("tipoServico").value,
          qtd_CEUs: document.getElementById("qtdCEUs").value,
          qtd_dashboards: document.getElementById("qtdDashboard").value,
          previsao_fechamento: document.getElementById("previFechamento").value,
        },
        tags: ["Contato", props.nomeLP],
        notes: ["Contato enviado através de " + props.nomeLP],
      },
    ];

    // DATA
    let dataToSend = {
      rules: rules,
      leads: lead,
    };

    fetch(endpoint, {
      headers: {
        "Content-Type": "text/plain",
      },
      method: "POST",
      body: JSON.stringify(dataToSend, getCircularReplacer()),
    })
      .then((response) => {
        // Retorno do Ajax
        console.log(response);
        let warning = document.getElementById("formValido");
        warning.style.display = "flex";
        //ga('send', 'event', 'form', 'contato', 'fale-consultor');
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <section>
      <div className="container-subs">
        <div className="header-subs">
          <img src={Google} />
        </div>
      </div>
      <div className="form-header">
        <h2>
          CADASTRE SUA NOVA <br /> OPORTUNIDADE
        </h2>
      </div>
      <div className="divisor">
        <img src={Divisor} />
      </div>
      <div className="form">
        <form>
          <div className="form-control">
            <InputMask
              required
              onBlur={checkCNPJ}
              id="CNPJ"
              mask="99.999.999/9999-99"
              onChange={(text) => {
                setFormValues({ CNPJ: text });
              }}
              type={"text"}
              data
              placeholder="CNPJ"
            />
            <InputMask
              required
              id="razaoSocial"
              onChange={(text) => {
                setFormValues({ razaoSocial: text });
              }}
              type={"text"}
              placeholder="Razão Social da Oportunidade"
            />
            <InputMask
              id="dominioEducacional"
              required
              onChange={(text) => {
                setFormValues({ dominioEducacional: text });
              }}
              type={"text"}
              placeholder="Domínio Educacional"
            />
            <InputMask
              id="nomeCompleto"
              required
              onChange={(text) => {
                setFormValues({ nomeCompleto: text });
              }}
              type={"text"}
              placeholder="Nome Completo do Contato"
            />
            <InputMask
              id="cargoContato"
              required
              onChange={(text) => {
                setFormValues({ cargoContato: text });
              }}
              type={"text"}
              placeholder="Cargo do Contato"
            />
            <InputMask
              id="telefoneContato"
              required
              mask="(99) 99999-9999"
              onChange={(text) => {
                setFormValues({ telefoneContato: text });
              }}
              type={"tel"}
              className="tel"
              placeholder="Telefone do Contato"
            />
            <InputMask
              id="emailContato"
              required
              onChange={(text) => {
                setFormValues({
                  emailContato: text,
                  id: text,
                });
              }}
              type={"email"}
              placeholder="E-mail do Contato"
            />
          </div>
          <div className="cidade">
            <InputMask
              required
              onChange={(text) => {
                setFormValues({ ufContato: text });
              }}
              type={"text"}
              id="UF"
              maxLength={2}
              placeholder="UF"
            />
            <InputMask
              required
              onChange={(text) => {
                setFormValues({ cidadeContato: text });
              }}
              type={"text"}
              id="city"
              placeholder="Cidade"
            />
          </div>
          <div className="form-control">
            <InputMask
              id="Endereco"
              required
              onChange={(text) => {
                setFormValues({ Endereco: text });
              }}
              type={"text"}
              placeholder="Endereço"
            />
            <InputMask
              id="CEP"
              mask="99999-999"
              required
              onChange={(text) => {
                setFormValues({ CEP: text });
              }}
              type={"text"}
              placeholder="CEP"
            />
            <InputMask
              id="qtdProfessores"
              required
              onChange={(text) => {
                setFormValues({ qtdProfessores: text });
              }}
              type={"number"}
              placeholder="Quantidade de Professores"
            />
            <InputMask
              id="tipoServico"
              required
              onChange={(text) => {
                setFormValues({ tipoServico: text });
              }}
              type={"text"}
              placeholder="Tipo de Serviço"
            />
            <InputMask
              id="qtdCEUs"
              required
              onChange={(text) => {
                setFormValues({ qtdCEUs: text });
              }}
              type={"number"}
              placeholder="Quantidadede CEUs"
            />
            <InputMask
              id="qtdDashboard"
              required
              onChange={(text) => {
                setFormValues({ qtdDashboard: text });
              }}
              type={"number"}
              placeholder="Quantidade de Dashboard"
            />
            <InputMask
              id="previFechamento"
              required
              onChange={(text) => {
                setFormValues({ previFechamento: text });
              }}
              type={"text"}
              onFocus={onFocusChange}
              onBlur={onBlurChange}
              placeholder="Data prevista de publicação"
            />
          </div>
          <div className="action-button">
            <button
              onClick={() => {
                enviarFormulario();
              }}
              type="submit"
            >
              Enviar
            </button>
            <br></br>
            <h2 id="formValido" style={{ display: "none" }}>
              Formulário enviado
            </h2>
          </div>
        </form>
      </div>
    </section>
  );
}
