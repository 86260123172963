import React from "react";
import Footer from "../components/Footer/Footer";
import Hero from "../components/Hero/Hero";
import Historia from "../components/Historia/Historia";
import Inspire from "../components/Inspire/Inspire";
import NosEncontre from "../components/NosEncontre/NosEncontre";
import Quarteto from "../components/Quarteto/Quarteto";
import Somos from "../components/Somos/Somos";
import Timeline from "../components/Timeline/Timeline";

export default function Quem() {
  return (
    <>
      <Hero />
      <Historia />
      <Timeline />
      <Quarteto />
      <Inspire />
      <NosEncontre />
      <Footer />
    </>
  );
}
