import React from "react";
import { Routes, Route } from "react-router-dom";

import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";

// import Homepage from "./pages/Homepage";
// import ReviewDetails from "./pages/ReviewDetails";
// import Category from "./pages/Category";
// import SiteHeader from "./components/SiteHeader";

// Partners Landing Pages
import Inove from "./screens/Partners/Inove/screens/Inove";
import CHS from "./screens/Partners/CHS/screens/CHS";
import Aguia from "./screens/Partners/Águia/screens/Aguia";
import HT from "./screens/Partners/HTSolutions/screens/HT";
import Calriz from "./screens/Partners/Calriz/screens/Calriz";
import LM from "./screens/Partners/LuizMagalhaes/screens/LM";
import Expandel from "./screens/Partners/Expandel/screens/Expandel";

// GetEdu MAIN APPLICATION pages
import Quem from "./screens/GetEdu/QuemSomos/screens/Quem";
import Home from "./screens/GetEdu/Home/Home";
import Solucoes from "./screens/GetEdu/Solucoes/screens/Solucoes";

const client = new ApolloClient({
  uri: "https://admin.getedu.tec.br/graphql",
  headers: {
    "Authorization": "Bearer 200ac63313fb87dcbedfdca312ba7ac367d07fce2d2cb4c06186390ba2e660694023171aea6c9c3ff04fca65cbd17e8f6bc0e0eaf6357bb0b9096ecc4375724ac4c3deb01c94704042effe4cd2549dc24eb40e878de126591219cefb8e2e5f37a34315a8ce2ba3c74b43310ad675ec88607affb7fc5b76f0f1a7347b19b06ec5",
  },
  cache: new InMemoryCache(),
});


function App() {
  return (
    <ApolloProvider client={client}>
      <div className="App">
        {/* <SiteHeader /> */}
        <Routes>
          {/* <Route exact path="/" element={<Homepage />} /> */}
          <Route
            path="/oportunidadetecnologiaeducacional"
            element={<Inove />}
          />
          <Route path="/parceriatecnologiaeducacional" element={<CHS />} />
          <Route path="/sudestetecnologiaeducacional" element={<Aguia />} />
          <Route path="/sultecnologiaeducacional" element={<HT />} />
          <Route path="/oportunidadeparceiroSP" element={<Calriz />} />
          <Route path="/lmoportunidadeMG" element={<LM />} />
          <Route path="/quem-somos" element={<Quem />} />
          <Route path="/regiaosul" element={<Expandel />} />
          <Route path="/solucoes" element={<Solucoes />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </div>
    </ApolloProvider>
  );
}

export default App;
