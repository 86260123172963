import React, { useState } from "react";
import "./navbar.css";
import Nuvem from "../../screens/GetEdu/QuemSomos/assets/nuvem.svg";
import Styles from "../../screens/GetEdu/QuemSomos/components/Quem.module.css";
import { Link } from "react-router-dom";
export default function Navbar() {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  return (
    <nav className={"navigation"}>
      <a href="/" className="brand-name">
        <div className={Styles.icon}>
          <img src={Nuvem} alt="cloud" />
        </div>
      </a>
      <button
        className="hamburger"
        onClick={() => {
          setIsNavExpanded(!isNavExpanded);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          viewBox="0 0 20 20"
          fill="white"
        >
          <path
            fillRule="evenodd"
            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      <div
        className={
          isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
        }
      >
        <ul>
          <li>
            <Link to="/">
              <p>Home</p>
            </Link>
          </li>
          <li>
            <Link to="/quem-somos">
              <p>Somos a GetEdu</p>
            </Link>
          </li>
          <li>
            <Link to="/solucoes">
              <p>Soluções</p>
            </Link>
          </li>
          {/* <li>
            <p href="/contact">Espaço GetEdu
          </li>
          <li>
            <p href="/contact">Criamos Cultura</p>
          </li>
          <li>
            <p href="/contact">CrieAtive</p>
          </li> */}
        </ul>
      </div>
    </nav>
  );
}
