import React from "react";

import Logo from "../../assets/logo.svg"
import Man from "../../assets/man.svg"
import Aguia from "../../assets/aguia.svg"
import Nuvem from "../../assets/nuvem.svg"
import Styles from "../inove.module.css"
export default function Hero() {
    return (
        <section id="hero" className={Styles.heroInove} >
            <div class="heroBody" className={Styles.heroBody}>
                <div class="container">
                    <div className={Styles.icon}>
                        <img src={Nuvem} />
                    </div>

                    <div className={Styles.miniContainer}>
                        <div className={Styles.header}>
                            <img src={Logo} />
                            <img id="inove" className={Styles.inove} src={Aguia} />
                        </div>

                        <div id="man" className={Styles.man}>
                            <img src={Man} />
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}