import React from "react";
import Styles from "../Quem.module.css";
import Um from "../../assets/slide/primeiroSlide.png";
import Dois from "../../assets/slide/segundoSlide.png";
import Tres from "../../assets/slide/terceiroSlide.png";
import Quatro from "../../assets/slide/quartoSlide.png";

import Daniel from "../../assets/slideMobile/daniel.svg";
import Marcele from "../../assets/slideMobile/marcele.svg";
import Well from "../../assets/slideMobile/well.svg";
import Jose from "../../assets/slideMobile/jose.svg";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { useQuery, gql } from "@apollo/client";
import parse from "html-react-parser";

const Query = gql`
  query GetHero {
    quartetoComponent {
      data {
        attributes {
          Founder1 {
            data {
              attributes {
                url
              }
            }
          }
          FounderName1
          FounderPosition1
          FounderDesc1
          Founder2 {
            data {
              attributes {
                url
              }
            }
          }
          FounderName2
          FounderPosition2
          FounderDesc2
          Founder3 {
            data {
              attributes {
                url
              }
            }
          }
          FounderName3
          FounderPosition3
          FounderDesc3
          Founder4 {
            data {
              attributes {
                url
              }
            }
          }
          FounderName4
          FounderPosition4
          FounderDesc4
        }
      }
    }
  }
`;

export default function Quarteto() {
  const { loading, error, data } = useQuery(Query);

  const baseUrl = "https://admin.getedu.tec.br/";

  return (
    <section className={Styles.defaultSection}>
      <div className={Styles.quartetoContainerMobile}>
        <Carousel
          className={Styles.sliderCenter}
          preventMovementUntilSwipeScrollTolerance={true}
          swipeScrollTolerance={50}
          emulateTouch={true}
          autoPlay={false}
          showThumbs={false}
          showStatus={false}
          showIndicators={false}
          width={"80%"}
        >
          <div className={Styles.quartetoFlow}>
            <img
              src={
                baseUrl +
                data?.quartetoComponent.data.attributes.Founder1.data.attributes
                  .url
              }
              alt=""
            />
            <div className={Styles.quartetoFlowDesc}>
              <div className={Styles.Daniel}>
                <h1 className={Styles.founderName}>
                  {parse(
                    `${data?.quartetoComponent.data.attributes.FounderName1}`
                  )}
                </h1>
                <h3 className={Styles.founderPosition}>
                  {parse(
                    `${data?.quartetoComponent.data.attributes.FounderPosition1}`
                  )}
                </h3>
                <p>
                  {parse(
                    `${data?.quartetoComponent.data.attributes.FounderDesc1}`
                  )}
                </p>
              </div>
            </div>
          </div>

          <div className={Styles.quartetoFlow}>
            <img
              src={
                baseUrl +
                data?.quartetoComponent.data.attributes.Founder2.data.attributes
                  .url
              }
              width={50}
              height={500}
              alt=""
            />
            <div className={Styles.quartetoFlowDesc}>
              <div className={Styles.Jose}>
                <h1 className={Styles.founderName}>
                  {parse(
                    `${data?.quartetoComponent.data.attributes.FounderName2}`
                  )}
                </h1>
                <h3 className={Styles.founderPosition}>
                  {parse(
                    `${data?.quartetoComponent.data.attributes.FounderPosition2}`
                  )}
                </h3>
                <p>
                  {parse(
                    `${data?.quartetoComponent.data.attributes.FounderDesc2}`
                  )}
                </p>
              </div>
            </div>
          </div>

          <div className={Styles.quartetoFlow}>
            <img
              src={
                baseUrl +
                data?.quartetoComponent.data.attributes.Founder3.data.attributes
                  .url
              }
              width={50}
              height={500}
              alt=""
            />
            <div className={Styles.quartetoFlowDesc}>
              <div className={Styles.Marcele}>
                <h1 className={Styles.founderName}>
                  {parse(
                    `${data?.quartetoComponent.data.attributes.FounderName3}`
                  )}
                </h1>
                <h3 className={Styles.founderPosition}>
                  {parse(
                    `${data?.quartetoComponent.data.attributes.FounderPosition3}`
                  )}
                </h3>
                <p>
                  {parse(
                    `${data?.quartetoComponent.data.attributes.FounderDesc3}`
                  )}
                </p>
              </div>
            </div>
          </div>

          <div className={Styles.quartetoFlow}>
            <img
              src={
                baseUrl +
                data?.quartetoComponent.data.attributes.Founder4.data.attributes
                  .url
              }
              width={50}
              height={500}
              alt=""
            />
            <div className={Styles.quartetoFlowDesc}>
              <div className={Styles.Well}>
                <h1 className={Styles.founderName}>
                  {parse(
                    `${data?.quartetoComponent.data.attributes.FounderName4}`
                  )}
                </h1>
                <h3 className={Styles.founderPosition}>
                  {parse(
                    `${data?.quartetoComponent.data.attributes.FounderPosition4}`
                  )}
                </h3>
                <p>
                  {" "}
                  {parse(
                    `${data?.quartetoComponent.data.attributes.FounderDesc4}`
                  )}
                </p>
              </div>
            </div>
          </div>
        </Carousel>
      </div>

      <div className={Styles.quartetoContainerDesktop}>
        <Carousel
          className={Styles.sliderCenter}
          emulateTouch={true}
          autoPlay={false}
          showThumbs={false}
          showStatus={false}
          showIndicators={false}
          width={"80%"}
        >
          <div className={Styles.quartetoFlow}>
            <img
              src={
                baseUrl +
                data?.quartetoComponent.data.attributes.Founder1.data.attributes
                  .url
              }
              width={50}
              height={500}
              alt=""
              className={Styles.quartetoImage}
            />
            <div className={Styles.quartetoFlowDesc}>
              <div className={Styles.Daniel}>
                <h1 className={Styles.founderName}>
                  {parse(
                    `${data?.quartetoComponent.data.attributes.FounderName1}`
                  )}
                </h1>
                <h3 className={Styles.founderPosition}>
                  {parse(
                    `${data?.quartetoComponent.data.attributes.FounderPosition1}`
                  )}
                </h3>
                <p>
                  {parse(
                    `${data?.quartetoComponent.data.attributes.FounderDesc1}`
                  )}
                </p>
              </div>
            </div>
          </div>

          <div className={Styles.quartetoFlow}>
            <img
              src={
                baseUrl +
                data?.quartetoComponent.data.attributes.Founder2.data.attributes
                  .url
              }
              width={50}
              height={500}
              alt=""
              className={Styles.quartetoImage}
            />
            <div className={Styles.quartetoFlowDesc}>
              <div className={Styles.Jose}>
                <h1 className={Styles.founderName}>
                  {parse(
                    `${data?.quartetoComponent.data.attributes.FounderName2}`
                  )}
                </h1>
                <h3 className={Styles.founderPosition}>
                  {parse(
                    `${data?.quartetoComponent.data.attributes.FounderPosition2}`
                  )}
                </h3>
                <p>
                  {parse(
                    `${data?.quartetoComponent.data.attributes.FounderDesc2}`
                  )}
                </p>
              </div>
            </div>
          </div>

          <div className={Styles.quartetoFlow}>
            <img
              src={
                baseUrl +
                data?.quartetoComponent.data.attributes.Founder3.data.attributes
                  .url
              }
              width={50}
              height={500}
              alt=""
              className={Styles.quartetoImage}
            />
            <div className={Styles.quartetoFlowDesc}>
              <div className={Styles.Marcele}>
                <h1 className={Styles.founderName}>
                  {parse(
                    `${data?.quartetoComponent.data.attributes.FounderName3}`
                  )}
                </h1>
                <h3 className={Styles.founderPosition}>
                  {parse(
                    `${data?.quartetoComponent.data.attributes.FounderPosition3}`
                  )}
                </h3>
                <p>
                  {parse(
                    `${data?.quartetoComponent.data.attributes.FounderDesc3}`
                  )}
                </p>
              </div>
            </div>
          </div>

          <div className={Styles.quartetoFlow}>
            <img
              src={
                baseUrl +
                data?.quartetoComponent.data.attributes.Founder4.data.attributes
                  .url
              }
              width={50}
              height={500}
              alt=""
              className={Styles.quartetoImage}
            />
            <div className={Styles.quartetoFlowDesc}>
              <div className={Styles.Well}>
                <h1 className={Styles.founderName}>
                  {parse(
                    `${data?.quartetoComponent.data.attributes.FounderName4}`
                  )}
                </h1>
                <h3 className={Styles.founderPosition}>
                  {parse(
                    `${data?.quartetoComponent.data.attributes.FounderPosition4}`
                  )}
                </h3>
                <p>
                  {" "}
                  {parse(
                    `${data?.quartetoComponent.data.attributes.FounderDesc4}`
                  )}
                </p>
              </div>
            </div>
          </div>
        </Carousel>
      </div>
    </section>
  );
}
