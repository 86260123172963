import React from "react";
import Footer from "../QuemSomos/components/Footer/Footer";
import Hero from "./components/HeroHome/Hero";
import Historia from "../QuemSomos/components/Historia/Historia";
import Quarteto from "../QuemSomos/components/Quarteto/Quarteto";
import Inspire from '../QuemSomos/components/Inspire/Inspire';
import Editorial from "../Solucoes/components/Editorial/Editorial";
export default function Home() {
  return (
    <>
      <Hero />
      <Historia />
      <Editorial />
      <Quarteto />
      <Inspire />
      <Footer />
    </>
  );
}
