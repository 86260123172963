import React, { useState } from "react";
import Navbar from "../../../../../components/Navbar/Navbar";
import "../../../../../components/Navbar/navbar.css";
import Styles from "../home.module.css";
import"../dots.css";
import Logo from "../assets/logo.svg";
import { Carousel } from "react-responsive-carousel";
import { useQuery, gql } from "@apollo/client";
import parse from "html-react-parser";
import { useEffect } from "react";

const Query = gql`
  query GetHome {
    home {
      data {
        attributes {
          Header
          Link1
          Link2
          logotipo {
            data {
              attributes {
                url
              }
            }
          }
          Imagem1 {
            data {
              attributes {
                url
              }
            }
          }
          Imagem2 {
            data {
              attributes {
                url
              }
            }
          }
          Mobile1 {
            data {
              attributes {
                url
              }
            }
          }
          Mobile2 {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export default function Hero() {
  const { loading, error, data } = useQuery(Query);

  const [isNavExpanded, setIsNavExpanded] = useState(false);

  const baseUrl = "https://admin.getedu.tec.br/";

  const Link1 = parse(`${data?.home.data?.attributes.Link1}`);
  const Link2 = parse(`${data?.home.data?.attributes.Link2}`);

  function DesktopCarrossel() {
    return (
      <Carousel
        className={Styles.HomeCarousel}
        emulateTouch={true}
        autoPlay={false}
        showThumbs={false}
        showStatus={false}
        showIndicators={true}
        showArrows={true}
        width={"100%"}
        preventMovementUntilSwipeScrollTolerance={true}
        swipeScrollTolerance={50}
      >
        <a href={Link1}>
          <div>
            <img
              src={
                baseUrl + data?.home.data.attributes.Imagem1.data.attributes.url
              }
            />
          </div>
        </a>
        <a href={Link2}>
          <div>
            <img
              src={
                baseUrl + data?.home.data.attributes.Imagem2.data.attributes.url
              }
            />
          </div>
        </a>
        {/* <div
          style={
            data?.home.data.attributes.Imagem3.data.attributes.url === undefined
              ? Styles.globalHidden
              : Styles.globalDisplay
          }
        >
          <img
            src={
              baseUrl + data?.home.data.attributes.Imagem3.data.attributes.url
            }
          />
        </div> */}
      </Carousel>
    );
  }

  function MobileCarrossel() {
    return (
      <Carousel
        className={Styles.HomeCarousel}
        emulateTouch={true}
        autoPlay={false}
        showThumbs={false}
        showStatus={false}
        showIndicators={true}
        showArrows={true}
        width={"100%"}
        preventMovementUntilSwipeScrollTolerance={true}
        swipeScrollTolerance={50}
      >
        <a href={Link1}>
          <div>
            <img
              src={
                baseUrl + data?.home.data.attributes.Mobile1.data.attributes.url
              }
            />
          </div>
        </a>
        <a href={Link2}>
          <div>
            <img
              src={
                baseUrl + data?.home.data.attributes.Mobile2.data.attributes.url
              }
            />
          </div>
        </a>
      </Carousel>
    );
  }

  return (
    <>
      <section className={Styles.homeSection}>
        <div className={Styles.HeroNav}>
          <div>
            <Navbar />
          </div>
        </div>
        <div id="hero-aguia" className={Styles.heroHome}>
          <div className={Styles.heroBody}>
            <div class={Styles.homeContainer}>
              <div className={Styles.miniContainer}>
                <div className={Styles.header}>
                  <div className={Styles.homeCarrosselDesktop}>
                    <DesktopCarrossel />
                  </div>
                  <div className={Styles.homeCarrosselMobile}>
                    <MobileCarrossel />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
