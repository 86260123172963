import React from "react";
import Styles from "../Quem.module.css";
import EncontreImg from "../../assets/nosEncontre.svg";
import marcele from "../../assets/marcele.png";
import { useQuery, gql } from "@apollo/client";
import parse from "html-react-parser";

const Query = gql`
  query GetHero {
    quemSomosTela {
      data {
        attributes {
          NosEncontreTitulo
          NosEncontreTexto
          NosEncontreDesktop {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export default function NosEncontre() {
  const { loading, error, data } = useQuery(Query);
  const baseUrl = "https://admin.getedu.tec.br/";

  return (
    <section className={Styles.defaultSection}>
      <div className={Styles.nosEncontreContainer}>
        <div className={Styles.nosEncontreFirstSquare}>
          {/* <p>Nos encontre</p> */}
          {/* <img src={marcele} alt="Marcele" /> */}
          <p>{parse(`${data?.quemSomosTela.data.attributes.NosEncontreTitulo}`)}</p>
          <img
            src={
              baseUrl +
              data?.quemSomosTela.data.attributes.NosEncontreDesktop.data
                .attributes.url
            }
            alt=""
          />
        </div>
        <div className={Styles.nosEncontreSecondSquare}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
              marginTop: "-110px",
              maxHeight: "97px",
            }}
          >
            <div className={Styles.nosEncontreRedSquare}></div>
            <div className={Styles.nosEncontreGreenSquare}></div>
          </div>
          {/* <p>
            Vamos conversar sobre o seu próximo projeto! A inovação que você
            precisa em sua instituição começa com a GetEdu. Entre em contato
            conosco ou preencha o formulário ao lado para que um de nossos
            consultores possa ajudar você.
          </p> */}
           {parse(`${data?.quemSomosTela.data.attributes.NosEncontreTexto}`)}
        </div>
      </div>
    </section>
  );
}
