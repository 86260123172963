import Circle from "../../../assets/capbolaSelecao.svg";
import BlueCircle from "../../../assets/capbolaSelecaoAzul.svg";
import Styles from "../../Quem.module.css";

export default function QuemSomosLogo() {
  return (
    <div className={Styles.quemSomosLogo}>
      <h3>
      “Crie <b>cultura</b>,<br />
            gere <b>resultado</b>.”
      </h3>
      <div className={Styles.reticencias}>
        <img src={Circle} alt="circle" />
        <img src={Circle} alt="circle" />
        <img src={BlueCircle} alt="circle" />
      </div>
    </div>
  );
}
