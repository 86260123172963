import React, { useState } from "react";
import Navbar from "../../../../../components/Navbar/Navbar";
import "../../../../../components/Navbar/navbar.css";
import Logo from "../../assets/logo.svg";
import Man from "../../assets/man.svg";
import Nuvem from "../../assets/nuvem.svg";
import Styles from "../Quem.module.css";
import { useQuery, gql } from "@apollo/client";
import QuemSomosLogo from "./QuemSomosLogo";

const Query = gql`
  query GetHero {
    quemSomosTela {
      data {
        attributes {
          Woman {
            data {
              attributes {
                url
              }
            }
          }

          Logo {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;
export default function Hero() {
  const { loading, error, data } = useQuery(Query);

  const [isNavExpanded, setIsNavExpanded] = useState(false);

  const baseUrl = "https://admin.getedu.tec.br/";

  return (
    <section id="hero-aguia" className={Styles.heroAguia}>
      <Navbar />
      <div className="heroBody">
        <div class="container">
          <div className={Styles.miniContainer}>
            <div className={Styles.header}>
             <QuemSomosLogo />
            </div>

            <div id="man">
              <img
                src={
                  baseUrl +
                  data?.quemSomosTela.data.attributes.Woman.data.attributes.url
                }
                className={Styles.man}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
