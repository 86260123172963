import React, { useState } from "react";
import Styles from "../Quem.module.css";
import Divisor from "../../assets/divisor.svg";
import Block from "../../assets/carousel/mobileblocks.svg";
import One from "../../assets/carousel/1.svg";
import Two from "../../assets/carousel/2.svg";
import Three from "../../assets/carousel/3.svg";
import Four from "../../assets/carousel/4.svg";
import Five from "../../assets/carousel/5.svg";
import aprendendo from "../../assets/inspiradores/aprendendo.svg";
import celebre from "../../assets/inspiradores/celebre.svg";
import comunique from "../../assets/inspiradores/comunique.svg";
import crieValor from "../../assets/inspiradores/CrieValor.svg";
import fortaleca from "../../assets/inspiradores/fortaleca.svg";
import gentileza from "../../assets/inspiradores/gentileza.svg";
import gratiluz from "../../assets/inspiradores/gratiluz.svg";
import simplifique from "../../assets/inspiradores/simplifique.svg";
import sorria from "../../assets/inspiradores/sorria.svg";
import surpreenda from "../../assets/inspiradores/surpreenda.svg";
import viva from "../../assets/inspiradores/viva.svg";
import iconVermelho from "../../assets/inspiradores/inspire_se_vemelha_icon.svg";
import iconVerde from "../../assets/inspiradores/inspire_se_verde_icon.svg";
import iconAzul from "../../assets/inspiradores/inspire_se_azul_icon.svg";
import iconAmarelo from "../../assets/inspiradores/inspire_se_amarelo_icon.svg";
import iconCinza from "../../assets/inspiradores/inspire_se_cinza_icon.svg";
// import QuadroInspire from "../../assets/quadroInspire.svg";
// import CarouselDesktop from "../../assets/desktop/carousel.svg";
export default function Inspire() {
  const [
    activateBlueMobileBlockAnimation,
    setActivateBlueMobileBlockAnimation,
  ] = useState(false);
  return (
    <section className={Styles.defaultSection}>
      <div className={Styles.inspireContainer}>
        <img src={Divisor} alt="" />

        <h2 className={Styles.inspireTitle}>• Inspire-se</h2>
        <div className={Styles.imgCarousel}>
          <img className={Styles.mobileBlock} src={Block} alt="" />
          <div className={Styles.mobileTextContainer}>
            <h6 className={Styles.mobileTextInspireTitle}>Propósito </h6>
            <p className={Styles.mobileTextInspire}>Criar Cultura</p>
            <img
              alt=""
              src={iconVermelho}
              className={Styles.inspireIconeVermelho}
            />
          </div>
          <div className={Styles.mobileTextContainerTwo}>
            <p className={Styles.mobileTextInspireTitle}> Missão </p>
            <p className={Styles.mobileTextInspire}>
              {" "}
              Atuar em prol de uma cultura criativa e transformadora nos
              ambientes educacionais, de ensino e aprendizagem
            </p>
            <img
              alt=""
              src={iconAmarelo}
              className={Styles.inspireIconeAmarelo}
            />
          </div>
          <div className={Styles.mobileTextContainerThree}>
            <p className={Styles.mobileTextInspireTitle}> Visão </p>
            <p className={Styles.mobileTextInspire}>
              {" "}
              Ser o maior agente de aculturamento na transformação da educação
            </p>
            <img alt="" src={iconVerde} className={Styles.inspireIconeVerde} />
          </div>

          <div
            className={Styles.mobileTextContainerFour}
            onClick={() => {
              setActivateBlueMobileBlockAnimation(
                !activateBlueMobileBlockAnimation
              );
            }}
          >
            <img alt="" src={iconAzul} className={Styles.inspireIconeAzul} />
            <p className={Styles.mobileTextInspireTitle}> Valores </p>
            <p className={Styles.mobileTextInspireLeft}>
              {" "}
              Pratique a gratidão{" "}
              {activateBlueMobileBlockAnimation && (
                <img src={gratiluz} className={Styles.animatedIcon} alt="" />
              )}
            </p>
            <p className={Styles.mobileTextInspireRight}>
              {activateBlueMobileBlockAnimation && (
                <img src={sorria} className={Styles.animatedIcon} alt="" />
              )}
              Sorria e faça Sorrir{" "}
            </p>
            <p className={Styles.mobileTextInspireLeft}>
              {" "}
              Vá além e surpreenda{" "}
              {activateBlueMobileBlockAnimation && (
                <img src={surpreenda} className={Styles.animatedIcon} alt="" />
              )}
            </p>
            <p className={Styles.mobileTextInspireRight}>
              {activateBlueMobileBlockAnimation && (
                <img src={viva} className={Styles.animatedIcon} alt="" />
              )}
              Viva e trabalhe com garra{" "}
            </p>
            <p className={Styles.mobileTextInspireLeft}>
              {" "}
              Viva aprendendo{" "}
              {activateBlueMobileBlockAnimation && (
                <img src={aprendendo} className={Styles.animatedIcon} alt="" />
              )}{" "}
            </p>
            <p className={Styles.mobileTextInspireRight}>
              {activateBlueMobileBlockAnimation && (
                <img src={comunique} className={Styles.animatedIcon} alt="" />
              )}
              Comunique-se sincera e honestamente{" "}
            </p>
            <p className={Styles.mobileTextInspireLeft}>
              {" "}
              Crie valor, gere resultado{" "}
              {activateBlueMobileBlockAnimation && (
                <img src={crieValor} className={Styles.animatedIcon} alt="" />
              )}
            </p>
            <p className={Styles.mobileTextInspireRight}>
              {activateBlueMobileBlockAnimation && (
                <img src={simplifique} className={Styles.animatedIcon} alt="" />
              )}
              Simplifique, faça mais com menos{" "}
            </p>
            <p className={Styles.mobileTextInspireLeft}>
              {" "}
              Fortaleça o grupo, unidos vamos mais longe{" "}
              {activateBlueMobileBlockAnimation && (
                <img src={fortaleca} className={Styles.animatedIcon} alt="" />
              )}
            </p>
            <p className={Styles.mobileTextInspireRight}>
              {activateBlueMobileBlockAnimation && (
                <img src={gentileza} className={Styles.animatedIcon} alt="" />
              )}
              Espalhe gentileza, engrandeça as relações{" "}
            </p>
            <p className={Styles.mobileTextInspireLeft}>
              {" "}
              Celebre
              {activateBlueMobileBlockAnimation && (
                <img src={celebre} className={Styles.animatedIcon} alt="" />
              )}
            </p>
          </div>
          {/* <img className={Styles.five} src={Five} alt="" /> */}
          <div className={Styles.mobileTextContainerFive}>
            <p className={Styles.mobileTextInspireTitle}> Posicionamento </p>
            <p className={Styles.lastMobileTextInspire}>
              {" "}
              Por meio de inteligência e tecnologias educacionais, somos a
              referência no mercado no aculturamento assertivo que transforma os
              ambientes educacionais, de ensino e aprendizagem.
            </p>
            <img alt="" src={iconCinza} className={Styles.inspireIconeCinza} />
          </div>
        </div>

        <div className={Styles.carouselDesktop}>
          {/* <img src={CarouselDesktop} alt="" /> */}
          <div className={Styles.firstColumn}>
            <div className={Styles.blockOne}>
              <img className={Styles.one} src={One} alt="" />
              <div className={Styles.textContainer}>
                <h6 className={Styles.textInspireTitle}>Propósito </h6>
                <p className={Styles.textInspire}>Criar Cultura</p>
                <img
                  alt=""
                  src={iconVermelho}
                  className={Styles.inspireIconeVermelho}
                />
              </div>
            </div>
            <div className={Styles.blockTwo}>
              <img className={Styles.two} src={Two} alt="" />
              <div className={Styles.textContainerTwo}>
                <p className={Styles.textInspireTitle}> Missão </p>
                <p className={Styles.textInspire}>
                  {" "}
                  Atuar em prol de uma cultura criativa e transformadora nos
                  ambientes educacionais, de ensino e aprendizagem
                </p>
                <img
                  alt=""
                  src={iconAmarelo}
                  className={Styles.inspireIconeAmarelo}
                />
              </div>
              <div></div>
            </div>
            <div className={Styles.blockThree}>
              <img className={Styles.three} src={Three} alt="" />
              <div className={Styles.textContainerThree}>
                <p className={Styles.textInspireTitle}> Visão </p>
                <p className={Styles.textInspire}>
                  {" "}
                  Ser o maior agente de aculturamento na transformação da
                  educação
                </p>
                <img
                  alt=""
                  src={iconVerde}
                  className={Styles.inspireIconeVerde}
                />
              </div>
            </div>
          </div>
          <div className={Styles.secondColumn}>
            <div
              className={Styles.blockFour}
              onMouseEnter={() => {
                setActivateBlueMobileBlockAnimation(true);
              }}
              onMouseLeave={() => {
                setActivateBlueMobileBlockAnimation(false);
              }}
            >
              <img className={Styles.four} src={Four} alt="" />
              <div className={Styles.textContainerFour}>
                <p className={Styles.textInspireTitle}> Valores </p>
                <img
                  alt=""
                  src={iconAzul}
                  className={Styles.inspireIconeAzul}
                />{" "}
                <p className={Styles.textInspireLeft}>
                  Pratique a gratidão{" "}
                  {activateBlueMobileBlockAnimation && (
                    <img
                      src={gratiluz}
                      className={Styles.animatedIcon}
                      alt=""
                    />
                  )}
                </p>
                <p className={Styles.textInspireRight}>
                  {" "}
                  {activateBlueMobileBlockAnimation && (
                    <img src={sorria} className={Styles.animatedIcon} alt="" />
                  )}
                  Sorria e faça Sorrir{" "}
                </p>
                <p className={Styles.textInspireLeft}>
                  {" "}
                  Vá além e surpreenda{" "}
                  {activateBlueMobileBlockAnimation && (
                    <img
                      src={surpreenda}
                      className={Styles.animatedIcon}
                      alt=""
                    />
                  )}{" "}
                </p>
                <p className={Styles.textInspireRight}>
                  {activateBlueMobileBlockAnimation && (
                    <img src={viva} className={Styles.animatedIcon} alt="" />
                  )}
                  Viva e trabalhe com garra{" "}
                </p>
                <p className={Styles.textInspireLeft}>
                  {" "}
                  Viva aprendendo{" "}
                  {activateBlueMobileBlockAnimation && (
                    <img
                      src={aprendendo}
                      className={Styles.animatedIcon}
                      alt=""
                    />
                  )}{" "}
                </p>
                <p className={Styles.textInspireRight}>
                  {" "}
                  {activateBlueMobileBlockAnimation && (
                    <img
                      src={comunique}
                      className={Styles.animatedIcon}
                      alt=""
                    />
                  )}
                  Comunique-se sincera e honestamente{" "}
                </p>
                <p className={Styles.textInspireLeft}>
                  {" "}
                  Crie valor, gere resultado{" "}
                  {activateBlueMobileBlockAnimation && (
                    <img
                      src={crieValor}
                      className={Styles.animatedIcon}
                      alt=""
                    />
                  )}
                </p>
                <p className={Styles.textInspireRight}>
                  {" "}
                  {activateBlueMobileBlockAnimation && (
                    <img
                      src={simplifique}
                      className={Styles.animatedIcon}
                      alt=""
                    />
                  )}
                  Simplifique, faça mais com menos{" "}
                </p>
                <p className={Styles.textInspireLeft}>
                  {" "}
                  Fortaleça o grupo, unidos vamos mais longe{" "}
                  {activateBlueMobileBlockAnimation && (
                    <img
                      src={fortaleca}
                      className={Styles.animatedIcon}
                      alt=""
                    />
                  )}
                </p>
                <p className={Styles.textInspireRight}>
                  {activateBlueMobileBlockAnimation && (
                    <img
                      src={gentileza}
                      className={Styles.animatedIcon}
                      alt=""
                    />
                  )}
                  Espalhe gentileza, engrandeça as relações{" "}
                </p>
              </div>
            </div>
            <div className={Styles.blockFive}>
              <img className={Styles.five} src={Five} alt="" />
              <div className={Styles.textContainerFive}>
                <p className={Styles.textInspireTitle}> Posicionamento </p>
                <img
                  alt=""
                  src={iconCinza}
                  className={Styles.inspireIconeCinza}
                />{" "}
                <p
                  className={Styles.textInspire}
                  style={{
                    fontSize: "22px",
                    lineHeight: "200%",
                    marginTop: "-90px",
                  }}
                >
                  Por meio de inteligência e tecnologias educacionais, somos a
                  referência no mercado no aculturamento assertivo que
                  transforma os ambientes educacionais, de ensino e
                  aprendizagem.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
