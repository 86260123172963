import React, { useState } from "react";
import Styles from "../Solucoes.module.css";
import Circulo from "../../assets/bolaDoAcompanhamento.svg";
import NonSelectedCircle from "../../assets/capbolaSelecao.svg";
import SelectedCircle from "../../assets/capbolaSelecaoAzul.svg";
import parse from "html-react-parser";
import { useQuery, gql } from "@apollo/client";

const Query = gql`
  query GetServicosTecnicos {
    solucoesGetEdu {
      data {
        attributes {
          ImagemAcompanhamento {
            data {
              attributes {
                url
              }
            }
          }
          ServicosTecnicos
          ImplantacaoTitle
          ImplantacaoDesc
          TextWheel
          Atendimento
          Slide1
          Slide2
          Slide3
          Slide4
          Slide5
          AcompanhamentoTecnico
          AcompanhamentoTecnicoDesc
        }
      }
    }
  }
`;

export default function ServicosTecnicos() {
  const [slide, setSlide] = useState(0);
  const { loading, error, data } = useQuery(Query);
  const baseUrl = "https://admin.getedu.tec.br/";

  return (
    <>
      <section className={Styles.tecServices}>
        <h1>{data?.solucoesGetEdu.data.attributes.ServicosTecnicos}</h1>
        <div className={Styles.fundoCortado}>
          <div className={Styles.TecServDesc}>
            <div className={Styles.TecServContent}>
              <h2>
                <b>{data?.solucoesGetEdu.data.attributes.ImplantacaoTitle}</b>
              </h2>
              <p className={Styles.implantacaoDesc}>
                {parse(
                  `${data?.solucoesGetEdu.data.attributes.ImplantacaoDesc}`
                )}
              </p>
            </div>
            <div className={Styles.blueCircle}>
              <div className={Styles.whiteCircle}>
                <div className={Styles.redCircle}>
                  <p>{data?.solucoesGetEdu.data.attributes.TextWheel}</p>
                  {/* SLIDES */}
                  {slide === 0 && (
                    <div className={Styles.slide}>
                      <h1> {">>"} </h1>
                      <h3>
                        {data?.solucoesGetEdu.data.attributes.Atendimento}
                      </h3>
                    </div>
                  )}
                  {slide === 1 && (
                    <div className={Styles.slide}>
                      <h1>1</h1>
                      <h3>{data?.solucoesGetEdu.data.attributes.Slide1}</h3>
                    </div>
                  )}
                  {slide === 2 && (
                    <div className={Styles.slide}>
                      <h1>2</h1>
                      <h3>{data?.solucoesGetEdu.data.attributes.Slide2}</h3>
                    </div>
                  )}
                  {slide === 3 && (
                    <div className={Styles.slide}>
                      <h1>3</h1>
                      <h3>{data?.solucoesGetEdu.data.attributes.Slide3}</h3>
                    </div>
                  )}
                  {slide === 4 && (
                    <div className={Styles.slide}>
                      <h1>4</h1>
                      <h3>{data?.solucoesGetEdu.data.attributes.Slide4}</h3>
                    </div>
                  )}
                  {slide === 5 && (
                    <div className={Styles.slide}>
                      <h1>5</h1>
                      <h3>{data?.solucoesGetEdu.data.attributes.Slide5}</h3>
                    </div>
                  )}

                  {/* CIRCLES */}
                  <div className={Styles.slideCircles}>
                    <img
                      src={slide === 0 ? SelectedCircle : NonSelectedCircle}
                      alt="selectionCircle"
                      className={Styles.firstCircle}
                      onClick={() => setSlide(0)}
                    />
                    <img
                      src={slide === 1 ? SelectedCircle : NonSelectedCircle}
                      alt="selectionCircle"
                      className={Styles.secondCircle}
                      onClick={() => setSlide(1)}
                    />
                    <img
                      src={slide === 2 ? SelectedCircle : NonSelectedCircle}
                      alt="selectionCircle"
                      className={Styles.thirdCircle}
                      onClick={() => setSlide(2)}
                    />
                    <img
                      src={slide === 3 ? SelectedCircle : NonSelectedCircle}
                      alt="selectionCircle"
                      className={Styles.fourthCircle}
                      onClick={() => setSlide(3)}
                    />
                    <img
                      src={slide === 4 ? SelectedCircle : NonSelectedCircle}
                      alt="selectionCircle"
                      className={Styles.fifthCircle}
                      onClick={() => setSlide(4)}
                    />
                    <img
                      src={slide === 5 ? SelectedCircle : NonSelectedCircle}
                      alt="selectionCircle"
                      className={Styles.sixthCircle}
                      onClick={() => setSlide(5)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={Styles.circulosDoAcompanhamento}>
          <div className={Styles.circulosDoAcompanhamentoBg}>
            <img
              src={
                baseUrl +
                data?.solucoesGetEdu.data.attributes.ImagemAcompanhamento.data
                  .attributes?.url
              }
              alt="circle"
            />
          </div>
          <div className={Styles.acompanhamentoWrapper}>
            <div className={Styles.acompanhamentoDesc}>
              <h1>
                {data?.solucoesGetEdu.data.attributes.AcompanhamentoTecnico}
              </h1>
              {parse(
                `${data?.solucoesGetEdu.data.attributes.AcompanhamentoTecnicoDesc}`
              )}
            </div>
          </div>
        </div>
        {/* </div> */}
      </section>
    </>
  );
}
