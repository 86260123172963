import Styles from "../../components/Solucoes.module.css";
import vermelho from "../../assets/edudash_peca_vermelho.svg";
import verde from "../../assets/edudash_peca_verde.svg";
import azul from "../../assets/edudash_peca_azul.svg";
import amarelo from "../../assets/edudash_peca_amarelo2.svg";
import circuloBase from "../../assets/edudash_circulo_base.svg";
import { useState } from "react";

export default function Edudash() {
  // states das mensagens que aparecerão no hover do circulo
  const [direcao, setDirecao] = useState(false);
  const [professores, setProfessores] = useState(false);
  const [responsaveis, setResponsaveis] = useState(false);
  const [secretaria, setSecretaria] = useState(false);

  return (
    <div className={Styles.edudashSection}>
      <div className={Styles.edudashContainer}>
        <div>
          <h1 className={Styles.edudashHeader}>EDUDASH</h1>
          <p>
            O EduDash é um aplicativo voltado para o ecossistema de gestão de
            uma instituição de ensino e permite o diálogo entre quatro públicos
            principais: responsáveis, professores, direção e secretaria de
            educação. Entenda abaixo como cada um deles se relaciona por meio do
            app:
          </p>
        </div>
      </div>
      <div className={Styles.edudashCircle}>
        <img className={Styles.baseCircle} src={circuloBase} alt="baseCircle" />

        <img
          onMouseEnter={() => {
            setDirecao(true);
          }}
          onMouseLeave={() => {
            setDirecao(false);
          }}
          className={Styles.vermelho}
          src={vermelho}
          alt="baseCircle"
        />
        <img
          className={Styles.verde}
          src={verde}
          alt="baseCircle"
          onMouseEnter={() => {
            setResponsaveis(true);
          }}
          onMouseLeave={() => {
            setResponsaveis(false);
          }}
        />
        <img
          className={Styles.azul}
          src={azul}
          alt="baseCircle"
          onMouseEnter={() => {
            setProfessores(true);
          }}
          onMouseLeave={() => {
            setProfessores(false);
          }}
        />
        <img
          className={Styles.amarelo}
          src={amarelo}
          alt="baseCircle"
          onMouseEnter={() => {
            setSecretaria(true);
          }}
          onMouseLeave={() => {
            setSecretaria(false);
          }}
        />
      </div>

      {professores && (
        <div className={Styles.circleInfo}>
          <p>Professores inserem dados correspondentes a:</p>
          <ul>
            <li>Avaliação socioemocional do aluno;</li>
            <li>Observações sobre saúde física do aluno;</li>
            <li>Pontuações sobre disciplina em aula;</li>
            <li>Notificação de presença ou falta;</li>
            <li>Notícias relevantes sobre atividades em classe.</li>
          </ul>
        </div>
      )}
      {direcao && (
        <div className={Styles.circleInfo}>
          <p>À direção cabe:</p>
          <ul>
            <li>
              Avaliação dos projetos da Secretaria de Educação em suas unidades
              escolares;
            </li>
            <li>Comunicação com os responsáveis pelos alunos;</li>
            <li>Notícias sobre a rotina escolar.</li>
          </ul>
        </div>
      )}
      {responsaveis && (
        <div className={Styles.circleInfo}>
          <p>Responsáveis dos alunos avaliam e recebem informações sobre:</p>
          <ul>
            <li>Infraestrutura do colégio;</li>
            <li>Merenda escolar;</li>
            <li>Qualidade do ensino;</li>
            <li>Presença do aluno;</li>
            <li>Noticias da escola.</li>
          </ul>
        </div>
      )}

      {secretaria && (
        <div className={Styles.circleInfo}>
          <p>O EduDash permite à Secretaria de Educação::</p>
          <ul>
            <li>Maior gestão sobre as escolas do município; </li>
            <li>Notícias sobre o calendário escolar.</li>
          </ul>
        </div>
      )}
    </div>
  );
}
