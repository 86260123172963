import Divisor from "../../assets/divisor.svg";
import Styles from "../Solucoes.module.css";
import Arrow from "../../assets/play-icon.svg";
import PruvoGrafismo from "../../assets/grafismo_pruvo.svg";
import Pruvo from "../../assets/pruvo.svg";
import parse from "html-react-parser";
import { useQuery, gql } from "@apollo/client";

const Query = gql`
  query GetPruvo {
    solucoesGetEdu {
      data {
        attributes {
          ProdutosComplementaresTitle
          PruvoDesc
        }
      }
    }
  }
`;

export default function ProdutosComplementares() {
  const { loading, error, data } = useQuery(Query);

  return (
    <div>
      <div className={Styles.produtosComplementaresHeader}>
        <h1>
          <b>
            {parse(
              `${data?.solucoesGetEdu.data.attributes.ProdutosComplementaresTitle}`
            )}
          </b>
        </h1>
        <img src={Divisor} alt="divisor" />
      </div>
      <div className={Styles.pruvoContainer}>
        <img src={Pruvo} alt="pruvo" />
      </div>
      <div className={Styles.produtosComplementaresSections}>
        <div className={Styles.produtosComplementaresFirstSection}>
          <img src={PruvoGrafismo} alt="pruvo" />
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>{parse(`${data?.solucoesGetEdu.data.attributes.PruvoDesc}`)}</div>
        </div>
        <div className={Styles.produtosComplementaresSecondSection}>
          <div>
            <h3>Entenda as funcionalidades:</h3>
            <ul>
              <li>
                <img src={Arrow} alt="arrow" />
                Elaboração de questões;
              </li>
              <li>
                <img src={Arrow} alt="arrow" />
                Banco de questões com todas as questões do ENEM e várias
                questões de outras instituições parceiras;
              </li>
              <li>
                <img src={Arrow} alt="arrow" />
                Gerenciamento de questões a partir de características como
                dificuldade, peso, formato, tipo, entre outras;
              </li>
              <li>
                <img src={Arrow} alt="arrow" />
                Montagem de provas;
              </li>
              <li>
                <img src={Arrow} alt="arrow" />
                Aplicação de provas com regras personalizadas de: período,
                grupo, embaralhamento, peso, equivalência e etc;
              </li>
              <li>
                <img src={Arrow} alt="arrow" />
                Aplicação em modo quiosque (única aplicação acessível no momento
                da avaliação);{" "}
              </li>
              <li>
                <img src={Arrow} alt="arrow" />
                Correção automática;
              </li>
              <li>
                <img src={Arrow} alt="arrow" />
                Micro-dados de aplicação e estatísticas pedagógicas.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
