import React from "react";
import Styles from "../Solucoes.module.css";
import Divisor from "../../assets/divisor.svg";
import OrangeArrow from "../../assets/play-icon-orange.svg";
import BlueArrow from "../../assets/play-icon-blue.svg";
import { Carousel } from "react-responsive-carousel";
import Gestao from "../../assets/carrosselNucleo/gestao.png";
import Formacao from "../../assets/carrosselNucleo/formacao.png";
import Live from "../../assets/carrosselNucleo/lives.png";
import Acomapanha from "../../assets/carrosselNucleo/acompanhamento.png";
import Assessoria from "../../assets/assessoriaPedagogica.svg";
import LivesImg from "../../assets/livesImg.svg";
import Play from "../../assets/play-icon-grey.svg";
import parse from "html-react-parser";
import { useQuery, gql } from "@apollo/client";

const Query = gql`
  query GetServicosPedagogicos {
    solucoesGetEdu {
      data {
        attributes {
          ServicosPedagogicos
          FormacaoNucleoGestor
          FormacaoEducadoresTitle
          FormacaoEducadoresDesc
          LivesGetEduOnAir
          LivesGetEduOnAirDesc
          AcompanhamentoPedagogicoTitle
          AcompanhamentoPedagogicoDesc
        }
      }
    }
  }
`;

export default function Pedagogicos() {
  const { loading, error, data } = useQuery(Query);

  return (
    <section className={Styles.pedagogicosSection}>
      <div className={Styles.pedagogicoHeader}>
        <h1>SERVIÇOS PEDAGÓGICOS</h1>
        <img src={Divisor} />
      </div>
      <div>
        {console.log(data?.solucoesGetEdu.data.attributes.FormacaoNucleoGestor)}
      </div>
      <Carousel
        className={Styles.carrosselContainer}
        preventMovementUntilSwipeScrollTolerance={true}
        swipeScrollTolerance={50}
        emulateTouch={true}
        autoPlay={false}
        showThumbs={true}
        showStatus={true}
        showArrows={true}
        showIndicators={true}
        width={"100%"}
      >
        <div className={Styles.pedagogicoColumns}>
          <div className={Styles.pedagogicoColumn}>
            <div className={Styles.pedagogicoColumnHeader}>
              <img src={Gestao} />
            </div>
            <div className={Styles.pedagogicoColumnContent}>
              <p>
                Este serviço possibilita que gestores da Secretaria de Educação,
                coordenadores, diretores e colaboradores, ou seja, aquelas
                pessoas que atuam junto ao Gestor principal da instituição ou
                rede de ensino, compreendam as potencialidades da plataforma
                Google Workspace for Education Fundamentals e saibam utilizá-la
                conforme as necessidades pedagógicas da instituição. Dessa
                forma, é possível acompanhar o desempenho de professores e
                alunos, disponibilizar conteúdos específicos tanto para a
                formação continuada dos educadores, quanto para nivelamento ou
                melhor absorção de conteúdos pelos estudantes, realizar a
                curadoria de conteúdos para simplificar o uso dos mesmos pelos
                docentes ou até mesmo receber feedbacks da rede. <br></br>
              </p>
              Conheça outros diferenciais:
              <ul>
                <li>
                  <img src={OrangeArrow} alt="arrow" /> Transferência de
                  conhecimento para a equipe local;
                </li>
                <li>
                  <img src={OrangeArrow} alt="arrow" /> Diminuição da curva de
                  aprendizagem;
                </li>
                <li>
                  <img src={OrangeArrow} alt="arrow" /> Maior autonomia da
                  equipe local de gestão;
                </li>
              </ul>
              {/* {parse(
                `${data?.solucoesGetEdu.data.attributes.FormacaoNucleoGestor}`
              )} */}
            </div>
          </div>

          <div className={Styles.pedagogicoColumn}>
            <div className={Styles.pedagogicoColumnHeader}>
              <img src={Formacao} />
            </div>
            <div className={Styles.pedagogicoColumnContent}>
              <img src={Play} className={Styles.pedagogicoColumnContentArrow} />
              <p>
                Uma etapa importantíssima para o uso das ferramentas Google
                Workspace for Education Fundamentals é a formação e
                acompanhamento dos educadores. Este serviço possibilita aos
                docentes a utilização das tecnologias Google Workspace for
                Education Fundamentals de uma maneira segura e inovadora. Os
                treinamentos são personalizados e podem ser realizados também
                pela equipe de multiplicadores da própria instituição.
              </p>
              Formações para uso do Google Workspace for Education Fundamentals:
              <ul>
                <li>
                  <img src={BlueArrow} alt="arrow" /> Move to Google Essential -
                  50h
                </li>
                <li>
                  <img src={BlueArrow} alt="arrow" /> Move to Google - 80h
                </li>
                <li>
                  <img src={BlueArrow} alt="arrow" /> Move to Google +
                  (avançado) - 80h
                </li>
                <li>
                  <img src={BlueArrow} alt="arrow" />
                  Apresentações impressionantes (Google Apresentações) - 34h
                </li>
                <li>
                  <img src={BlueArrow} alt="arrow" /> Quem tem medo de
                  planilhas? (Google Planilhas) - 34h
                </li>
                <li>
                  <img src={BlueArrow} alt="arrow" /> Eu nas telas! (Youtube) -
                  34h
                </li>
                <li>
                  <img src={BlueArrow} alt="arrow" /> Produtividade com Google -
                </li>
                <li>
                  <img src={BlueArrow} alt="arrow" /> Uso do Chromebook - 34h
                </li>
              </ul>
              <span
                style={{
                  marginTop: "12px",
                  display: "block",
                }}
              >
                Formações preparatórias para as certificações Google Educator:
              </span>
              <ul>
                <li>
                  <img src={BlueArrow} alt="arrow" /> Bootcamp Educator 1 - 26h
                </li>
                <li>
                  <img src={BlueArrow} alt="arrow" /> Bootcamp Educator 2 - 20h
                </li>
                <li>
                  <img src={BlueArrow} alt="arrow" /> Google Trainer - 15h
                </li>
              </ul>
              {/* {parse(
                `${data?.solucoesGetEdu.data.attributes.FormacaoEducadoresDesc}`
              )} */}
            </div>
          </div>
        </div>
        <div className={Styles.pedagogicoColumns}>
          <div className={Styles.pedagogicoColumn}>
            <div className={Styles.pedagogicoColumnHeader}>
              <img src={Acomapanha} />
            </div>
            <div className={Styles.pedagogicoColumnContent}>
              <img
                src={Play}
                className={Styles.pedagogicoColumnContentArrowLeft}
              />
              {/* <p>
                Neste serviço é oferecida uma assessoria para sanar dúvidas
                pedagógicas da sua equipe, com atendimento em horário comercial
                de segunda a sexta, durante o período contratado, que geralmente
                sugerimos que seja entre 12 e 60 meses. Dessa forma, a
                instituição ou secretaria de educação terá um suporte do time
                GetEdu, que fica à disposição para resolver quaisquer questões.
              </p> */}
              <p>
                {parse(
                  `${data?.solucoesGetEdu.data.attributes.AcompanhamentoPedagogicoDesc}`
                )}
              </p>

              {/* {parse(
                `${data?.solucoesGetEdu.data.attributes.AcompanhamentoPedagogicoDesc}`
              )} */}

              <div>
                <img src={Assessoria} />
              </div>
            </div>
          </div>
          <div className={Styles.pedagogicoColumn}>
            <div className={Styles.pedagogicoColumnHeader}>
              <img src={Live} />
            </div>
            <div className={Styles.pedagogicoColumnContent}>
              <p className={Styles.lastP}>
                O programa de lives da equipe GetEdu é parte complementar da
                habilitação dos professores na utilização dos produtos Google
                Workspace For Education Fundamentals, sendo disponibilizado para
                toda a rede de ensino. Estes encontros instrucionais acontecem
                via Google Meet ou Youtube e tem como objetivo tirar as dúvidas
                dos docentes quanto às ferramentas disponíveis na plataforma
                Google.
              </p>
              Saiba mais sobre as lives
              <ul>
                <li>
                  <img src={BlueArrow} alt="arrow" />
                  Encontros com 2 apresentadores e técnicos certificados pelo
                  Google acompanhando no chat;
                </li>
                <li>
                  <img src={BlueArrow} alt="arrow" /> Duração de cerca de 2h;
                </li>
                <li>
                  <img src={BlueArrow} alt="arrow" /> Contratada sob demanda.
                </li>
              </ul>
              <div>
                <img src={LivesImg} />
              </div>
            </div>
          </div>
        </div>
      </Carousel>
      <Carousel
        className={Styles.carrosselContainerMobile}
        emulateTouch={true}
        preventMovementUntilSwipeScrollTolerance={true}
        swipeScrollTolerance={50}
        autoPlay={false}
        showThumbs={false}
        showStatus={false}
        showIndicators={false}
        width={"100%"}
      >
        <div className={Styles.pedagogicoColumn}>
          <div className={Styles.pedagogicoColumnHeader}>
            <img src={Gestao} />
          </div>
          <div className={Styles.pedagogicoColumnContent}>
            <img src={Play} className={Styles.pedagogicoColumnContentArrow} />
            <p>
              Este serviço possibilita que gestores da Secretaria de Educação,
              coordenadores, diretores e colaboradores, ou seja, aquelas pessoas
              que atuam junto ao Gestor principal da instituição ou rede de
              ensino, compreendam as potencialidades da plataforma Google
              Workspace for Education Fundamentals e saibam utilizá-la conforme
              as necessidades pedagógicas da instituição. Dessa forma, é
              possível acompanhar o desempenho de professores e alunos,
              disponibilizar conteúdos específicos tanto para a formação
              continuada dos educadores, quanto para nivelamento ou melhor
              absorção de conteúdos pelos estudantes, realizar a curadoria de
              conteúdos para simplificar o uso dos mesmos pelos docentes ou até
              mesmo receber feedbacks da rede. <br></br>
            </p>
            Conheça outros diferenciais:
            <ul>
              <li>
                <img src={OrangeArrow} alt="arrow" /> Transferência de
                conhecimento para a equipe local;
              </li>
              <li>
                <img src={OrangeArrow} alt="arrow" /> Diminuição da curva de
                aprendizagem;
              </li>
              <li>
                <img src={OrangeArrow} alt="arrow" /> Maior autonomia da equipe
                local de gestão;
              </li>
            </ul>
          </div>
        </div>

        <div className={Styles.pedagogicoColumn}>
          <div className={Styles.pedagogicoColumnHeader}>
            <img src={Formacao} />
          </div>
          <div className={Styles.pedagogicoColumnContent}>
            <img
              src={Play}
              className={Styles.pedagogicoColumnContentArrowLeft}
            />
            <p>
              Uma etapa importantíssima para o uso das ferramentas Google
              Workspace for Education Fundamentals é a formação e acompanhamento
              dos educadores. Este serviço possibilita aos docentes a utilização
              das tecnologias Google Workspace for Education Fundamentals de uma
              maneira segura e inovadora. Os treinamentos são personalizados e
              podem ser realizados também pela equipe de multiplicadores da
              própria instituição.
            </p>
            Conheça as formações disponíveis:
            <ul>
              <li>
                <img src={BlueArrow} alt="arrow" />
                Formações para uso do Google Workspace for Education
                Fundamentals:
              </li>
              <li>
                <img src={BlueArrow} alt="arrow" /> Move to Google Essential -
                50h
              </li>
              <li>
                <img src={BlueArrow} alt="arrow" /> Move to Google - 80h
              </li>
              <li>
                <img src={BlueArrow} alt="arrow" /> Move to Google + (avançado)
                - 80h
              </li>
              <li>
                <img src={BlueArrow} alt="arrow" />
                Apresentações impressionantes (Google Apresentações) - 34h
              </li>
              <li>
                <img src={BlueArrow} alt="arrow" /> Quem tem medo de planilhas?
                (Google Planilhas) - 34h
              </li>
              <li>
                <img src={BlueArrow} alt="arrow" /> Eu nas telas! (Youtube) -
                34h
              </li>
              <li>
                <img src={BlueArrow} alt="arrow" /> Produtividade com Google -
              </li>
              <li>
                <img src={BlueArrow} alt="arrow" /> Uso do Chromebook - 34h
              </li>
            </ul>
          </div>
        </div>

        {/* <div className={Styles.pedagogicoColumn}>
          <div className={Styles.pedagogicoColumnHeader}>
            <img src={Live} />
          </div>
          <div className={Styles.pedagogicoColumnContent}>
            <p>
              Este serviço possibilita que gestores da Secretaria de Educação,
              coordenadores, diretores e colaboradores, ou seja, aquelas pessoas
              que atuam junto ao Gestor principal da instituição ou rede de
              ensino, compreendam as potencialidades da plataforma Google
              Workspace for Education Fundamentals e saibam utilizá-la conforme
              as necessidades pedagógicas da instituição. Dessa forma, é
              possível acompanhar o desempenho de professores e alunos,
              disponibilizar conteúdos específicos tanto para a formação
              continuada dos educadores, quanto para nivelamento ou melhor
              absorção de conteúdos pelos estudantes, realizar a curadoria de
              conteúdos para simplificar o uso dos mesmos pelos docentes ou até
              mesmo receber feedbacks da rede.
            </p>
            Conheça outros diferenciais:
            <ul>
              <li>Transferência de conhecimento para a equipe local;</li>
              <li>Diminuição da curva de aprendizagem;</li>
              <li>Maior autonomia da equipe local de gestão;</li>
            </ul>
          </div>
        </div>

        <div className={Styles.pedagogicoColumn}>
          <div className={Styles.pedagogicoColumnHeader}>
            <img src={Acomapanha} />
          </div>
          <div className={Styles.pedagogicoColumnContent}>
            <p>
              Neste serviço é oferecida uma assessoria para sanar dúvidas
              pedagógicas da sua equipe, com atendimento em horário comercial de
              segunda a sexta, durante o período contratado, que geralmente
              sugerimos que seja entre 12 e 60 meses. Dessa forma, a instituição
              ou secretaria de educação terá um suporte do time GetEdu, que fica
              à disposição para resolver quaisquer questões.
            </p>
            Conheça as formações disponíveis:
            <ul>
              <li>
                Formações para uso do Google Workspace for Education
                Fundamentals:
              </li>
              <li>Move to Google Essential - 50h</li>
              <li>Move to Google - 80h</li>
              <li>Move to Google + (avançado) - 80h</li>
              <li>
                Apresentações impressionantes (Google Apresentações) - 34h
              </li>
              <li>Quem tem medo de planilhas? (Google Planilhas) - 34h</li>
              <li>Eu nas telas! (Youtube) - 34h</li>
              <li>Produtividade com Google -</li>
              <li>Uso do Chromebook - 34h</li>
            </ul>
          </div>
        </div> */}
      </Carousel>
    </section>
  );
}
