import React from "react";
import Styles from "../Solucoes.module.css";
import Divisor from "../../assets/divisor.svg";
import Arrow from "../../assets/play-icon.svg";
import OrangeArrow from "../../assets/play-icon-orange.svg";
import Laptop from "../../assets/laptop.svg";
import Laptop2 from "../../assets/laptop2.svg";
import DashboardIconOne from "../../assets/dashboard_icone_1.svg";
import DashboardIconTwo from "../../assets/dashboard_icone_2.svg";
import DashboardIconThree from "../../assets/dashboard_icone_3.svg";
import { Carousel } from "react-responsive-carousel";
import parse from "html-react-parser";
import { useQuery, gql } from "@apollo/client";

const Query = gql`
  query GetGestao {
    solucoesGetEdu {
      data {
        attributes {
          ProdutosDeGestao
          LicencaCEUDesc
          LicencaCEUDesc2
          DashboardsGerenciaisTitle
          DashboardsGerenciaisDesc
        }
      }
    }
  }
`;

export default function ProdutosGestao() {
  const { loading, error, data } = useQuery(Query);
  return (
    <section>
      <div className={Styles.produtosGestaoHeader}>
        <h1>
          <b>
            {parse(
              `${data?.solucoesGetEdu.data.attributes.ProdutosDeGestao}`
            )}
          </b>
        </h1>
        <img src={Divisor} />
      </div>
      <div className={Styles.produtosGestaoColumns}>
        <div className={Styles.produtosGestaoColumn1Container}>
          <div className={Styles.produtosGestaoColumn1Content}>
            <h2>Gerenciamento de Chromebooks - Licença CEU</h2>
            <p>
            {parse(
              `${data?.solucoesGetEdu.data.attributes.LicencaCEUDesc}`
            )}
              <br></br>
              {parse(
              `${data?.solucoesGetEdu.data.attributes.LicencaCEUDesc2}`
            )}
            </p>
            <Carousel
              className={Styles.sliderCenter}
              emulateTouch={true}
              autoPlay={false}
              showThumbs={false}
              showStatus={false}
              showIndicators={false}
              showArrows={true}
              width={"100%"}
              preventMovementUntilSwipeScrollTolerance={true}
              swipeScrollTolerance={50}
            >
              <section className={Styles.section1CarouselContent}>
                <img
                  src={Laptop2}
                  alt="laptop2"
                  className={Styles.carouselImage}
                />
                Conheça outras vantagens:
                <ul>
                  <li>
                    <img src={Arrow} alt="arrow" /> Alta segurança de dados;
                  </li>
                  <li>
                    <img src={Arrow} alt="arrow" />
                    Rastreabilidade de uso;
                  </li>
                  <li>
                    <img src={Arrow} alt="arrow" />
                    SafeSearch sempre ativo e bloqueio de URLs inadequadas;
                  </li>
                  <li>
                    <img src={Arrow} alt="arrow" />
                    Utilização exclusiva com as contas da instituição;
                  </li>
                  <li>
                    <img src={Arrow} alt="arrow" />
                    Acesso ao histórico de alunos que utilizaram cada
                    Chromebook;
                  </li>
                  <li>
                    <img src={Arrow} alt="arrow" />
                    Bloqueio de apps (para instalação e durante a aplicação de
                    provas).
                  </li>
                </ul>
              </section>
              <section className={Styles.section1CarouselContent}>
                <img
                  src={Laptop}
                  alt="laptop"
                  className={Styles.carouselImage}
                />
                A licença CEU permite:
                <ul>
                  <li>
                    {" "}
                    <img src={Arrow} alt="arrow" /> Completo monitoramento do
                    equipamento (aplicações de configurações, aplicação de
                    políticas de uso e segurança, instalações massivas,
                    personalização);
                  </li>
                  <li>
                    <img src={Arrow} alt="arrow" />
                    Obtenção de dados de utilização, estatísticas por meio de
                    dashboards;
                  </li>
                  <li>
                    <img src={Arrow} alt="arrow" />
                    Acompanhamento da vida digital das contas registradas na
                    plataforma Google Workspace for Education da instituição.
                  </li>
                </ul>
              </section>
            </Carousel>
          </div>
        </div>
        <div className={Styles.produtosGestaoColumn2Container}>
          <div className={Styles.produtosGestaoColumn2Content}>
            <p>
            {parse(
              `${data?.solucoesGetEdu.data.attributes.DashboardsGerenciaisDesc}`
            )}
            </p>
            <div className={Styles.produtosGestaoSectionsContainer}>
              {/* a tag &nbsp; dá um espaço na linha */}
              <section>
                <h2>Conheça as dashboards da GetEdu:</h2>
                <span>
                  <img src={DashboardIconOne} alt="cel" />{" "}
                  <b>Device Dash:&nbsp;</b>
                  monitora a utilização de tablets;
                  <br></br>
                </span>
                <span>
                  <img src={DashboardIconTwo} alt="cel" />
                  <b>ChromeDash:&nbsp; </b>monitora a utilização de chromebooks;
                  <br></br>
                </span>

                <span>
                  <img src={DashboardIconThree} alt="cel" />
                  <b>DashEdu:&nbsp;</b>monitora o projeto como um todo;<br></br>
                </span>
              </section>
              <section>
                <h2>
                  Entre outros insights, as Dashboards apresentam dados como:
                </h2>
                <ul>
                  <li>
                    <img src={OrangeArrow} alt="arrow" /> Taxa de uso da
                    plataforma;
                  </li>
                  <li>
                    <img src={OrangeArrow} alt="arrow" /> Produção e
                    participação de professores nas atividades;
                  </li>
                  <li>
                    <img src={OrangeArrow} alt="arrow" />
                    Participação dos alunos nas aulas online;
                  </li>
                  <li>
                    <img src={OrangeArrow} alt="arrow" />
                    Georreferenciamento dos indicadores;
                  </li>
                  <li>
                    <img src={OrangeArrow} alt="arrow" />
                    Localização e taxa de utilização de chromebooks.
                  </li>
                </ul>
              </section>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
