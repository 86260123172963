import React from "react";
import Divisor from "../../assets/divisor.svg";
import Vantagens from "../../assets/hand.svg";
import Auxilio from "../../assets/hand2.svg";
import Styles from "../../components/Solucoes.module.css";
import PlayIcon from "../../assets/play-icon.svg";
import { Carousel } from "react-responsive-carousel";
import { useQuery, gql } from "@apollo/client";
import parse from "html-react-parser";

const Query = gql`
  query GetEditorial {
    solucoesGetEdu {
      data {
        attributes {
          IntroText
          TransformacaoDominioTitle
          TransformacaoDominioDesc
          VantagensTitle
          VantagensDesc
          VantagensTitle
          VantagensDesc
          AuxilioTitle
          AuxilioDesc
          VantagensDesc1
          VantagensDesc2
          VantagensDesc3
          VantagensDesc4
          AuxilioDesc1
          AuxilioDesc2
          AuxilioDesc3
        }
      }
    }
  }
`;
export default function Editorial() {
  const { loading, error, data } = useQuery(Query);

  const baseUrl = "https://admin.getedu.tec.br/";

  return (
    <section className={Styles.editorial}>
      <div className={Styles.editorialWrapper}>
        <div className={Styles.blocoTransformacao}>
          <div>
            <p>{parse(`${data?.solucoesGetEdu.data.attributes.IntroText}`)}</p>
          </div>

          <div>
            <div className={Styles.transformacaoTitle}>
              <h1>
                {parse(
                  `${data?.solucoesGetEdu.data.attributes.TransformacaoDominioTitle}`
                )}
              </h1>
              <div className={Styles.solucoesDivisor}>
                <img src={Divisor} alt="divisor" />
              </div>
            </div>
            <p>
              {parse(
                `${data?.solucoesGetEdu.data.attributes.TransformacaoDominioDesc}`
              )}
            </p>
          </div>
        </div>

        <div className={Styles.CarrosselVantagensAuxilio}>
          <Carousel
            preventMovementUntilSwipeScrollTolerance={true}
            swipeScrollTolerance={50}
            className={Styles.sliderCenter}
            emulateTouch={true}
            autoPlay={false}
            showThumbs={false}
            showStatus={false}
            showIndicators={false}
            width={"80%"}
          >
            <div className={Styles.VantagensAuxilio}>
              <img src={Vantagens} alt="benefits" />
              <div className={Styles.Vantagens}>
                <span>
                  {parse(
                    `${data?.solucoesGetEdu.data.attributes.VantagensTitle}`
                  )}
                </span>
              </div>
              <div className={Styles.VantagensBottom}>
                <ul>
                  <li>
                    <img src={PlayIcon} alt="arrow" />
                    <span>
                      {" "}
                      {parse(
                        `${data?.solucoesGetEdu.data.attributes.VantagensDesc1}`
                      )}
                    </span>
                  </li>
                  <li>
                    <img src={PlayIcon} alt="arrow" />{" "}
                    <span>
                      {" "}
                      {parse(
                        `${data?.solucoesGetEdu.data.attributes.VantagensDesc2}`
                      )}
                    </span>
                  </li>
                  <li>
                    <img src={PlayIcon} alt="arrow" />{" "}
                    <span>
                      {parse(
                        `${data?.solucoesGetEdu.data.attributes.VantagensDesc3}`
                      )}
                    </span>
                  </li>
                  <li>
                    <img src={PlayIcon} alt="arrow" />{" "}
                    <span>
                      {parse(
                        `${data?.solucoesGetEdu.data.attributes.VantagensDesc4}`
                      )}
                    </span>
                  </li>
                </ul>
              </div>
            </div>

            <div className={Styles.VantagensAuxilio}>
              <img src={Auxilio} width={"50%"} alt="assistance" />

              <div className={Styles.Auxilio}>
                <p>
                  {parse(
                    `${data?.solucoesGetEdu.data.attributes.AuxilioTitle}`
                  )}
                </p>
              </div>

              <div className={Styles.AuxilioBottom}>
                <ul>
                  <li>
                    <img src={PlayIcon} alt="arrow" />
                    <span>
                      {" "}
                      {parse(
                        `${data?.solucoesGetEdu.data.attributes.AuxilioDesc1}`
                      )}
                    </span>
                  </li>
                  <li>
                    <img src={PlayIcon} alt="arrow" />
                    <span>
                      {" "}
                      {parse(
                        `${data?.solucoesGetEdu.data.attributes.AuxilioDesc2}`
                      )}
                    </span>
                  </li>
                  <li>
                    <img src={PlayIcon} alt="arrow" />
                    <span>
                      {" "}
                      {parse(
                        `${data?.solucoesGetEdu.data.attributes.AuxilioDesc3}`
                      )}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </Carousel>
        </div>

        <div className={Styles.editorialDesc}>
          <div className={Styles.VantagensAuxilio}>
            <img src={Vantagens} alt="benefits" />
            <div className={Styles.Vantagens}>
              <p>
                {parse(
                  `${data?.solucoesGetEdu.data.attributes.VantagensTitle}`
                )}
              </p>
            </div>

            <div className={Styles.VantagensBottom}>
              <ul>
                <li>
                  <img src={PlayIcon} alt="arrow" />{" "}
                  {parse(
                    `${data?.solucoesGetEdu.data.attributes.VantagensDesc1}`
                  )}
                </li>
                <li>
                  <img src={PlayIcon} alt="arrow" />{" "}
                  {parse(
                    `${data?.solucoesGetEdu.data.attributes.VantagensDesc2}`
                  )}
                </li>
                <li>
                  <img src={PlayIcon} alt="arrow" />{" "}
                  {parse(
                    `${data?.solucoesGetEdu.data.attributes.VantagensDesc3}`
                  )}
                </li>
                <li>
                  <img src={PlayIcon} alt="arrow" />{" "}
                  {parse(
                    `${data?.solucoesGetEdu.data.attributes.VantagensDesc4}`
                  )}
                </li>
              </ul>

              {/* {parse(`${data?.solucoesGetEdu.data.attributes.VantagensDesc}`)} */}
            </div>
          </div>

          <div className={Styles.VantagensAuxilio}>
            <img src={Auxilio} alt="assistance" />

            <div className={Styles.Auxilio}>
              <p>
                {parse(`${data?.solucoesGetEdu.data.attributes.AuxilioTitle}`)}
              </p>
            </div>

            <div className={Styles.AuxilioBottom}>
              <ul>
                <li>
                  <img src={PlayIcon} alt="arrow" />
                  {parse(
                    `${data?.solucoesGetEdu.data.attributes.AuxilioDesc1}`
                  )}
                </li>
                <li>
                  <img src={PlayIcon} alt="arrow" />
                  {parse(
                    `${data?.solucoesGetEdu.data.attributes.AuxilioDesc2}`
                  )}
                </li>
                <li>
                  <img src={PlayIcon} alt="arrow" />
                  {parse(
                    `${data?.solucoesGetEdu.data.attributes.AuxilioDesc3}`
                  )}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
