import React from "react";
import Footer from "../../../GetEdu/QuemSomos/components/Footer/Footer";
import Hero from "../components/Hero/Hero";
import Subscribe from "../../components/Subscribe/Subscribe";

export default function Aguia() {
    return (
        <>
            <Hero />
            <Subscribe nomeLP="CHS"/>
            <Footer />
        </>
    )
}