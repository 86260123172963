import React from "react";

import Logo from "../../assets/logo.svg"
import Man from "../../assets/man.svg"
import Aguia from "../../assets/aguia.svg"
import Nuvem from "../../assets/nuvem.svg"
import Styles from "../Expandel.module.css"
export default function Hero() {
    return (
        <section id="hero" className={Styles.hero}>
            <div className="heroBody">
                <div class="container">
                    <div className={Styles.icon}>
                        <img src={Nuvem} />
                    </div>

                    <div className={Styles.miniContainer}>
                        <div className={Styles.header}>
                            <img src={Logo} />
                            <img id="aguia" className={Styles.aguia} src={Aguia} />
                        </div>

                        <div id="man">
                            <img src={Man} className={Styles.man} />
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}