import Styles from "../../Quem.module.css";

export default function Cards({ background, text, year }) {
  return (
    <div>
      <div
        className={
          background === "red"
            ? Styles.RedCardContainer
            : background === "blue"
            ? Styles.BlueCardContainer
            : background == "yellow"
            ? Styles.YellowCardContainer
            : Styles.GreenCardContainer
        }
      >
        <h3>{text}</h3>
      </div>
      <div
        className={
          background === "red"
            ? Styles.RedCardFooter
            : background === "blue"
            ? Styles.BlueCardFooter
            : background === "yellow"
            ? Styles.YellowCardFooter
            : Styles.GreenCardFooter
        }
      >
        {year}
      </div>
    </div>
  );
}
