import React from "react";
import Editorial from "../components/Editorial/Editorial";
import Hero from "../components/Hero/Hero";
import Pedagogicos from "../components/Pedagogicos/Pedagogicos";
import ServicosTecnicos from "../components/ServicosTecnicos/ServicosTecnicos";
import Footer from "../../QuemSomos/components/Footer/Footer";
import ProdutosGestao from "../components/ProdutosGestao/ProdutosGestao";
import Styles from "../components/Solucoes.module.css";
import ProdutosComplementares from "../components/ProdutosComplementares/ProdutosComplementares";
import Edudash from "../components/Edudash/Edudash";
export default function Solucoes() {
  return (
    <section className={Styles.solucoesMasterSection}>
      <Hero />
      <Editorial />
      <ServicosTecnicos />
      <Pedagogicos />
      <ProdutosGestao />
      <ProdutosComplementares />
      <Edudash />
      <Footer />
    </section>
  );
}
