import React, { useEffect, useRef, useState } from "react";
import Styles from "../Quem.module.css";
import TimelineImg from "../../assets/timeline.svg";
import TimelineLogo from "../../assets/timelineLogo.svg";
import HorizontalScroll from "react-scroll-horizontal";
import Cards from "./Cards/Cards";
import { motion } from "framer-motion";
import { useQuery, gql } from "@apollo/client";
import parse from "html-react-parser";

const Query = gql`
  query GetTimeline {
    timeline {
      data {
        attributes {
          txt2019
          txt2020
          txt2021
          txt2022
          txt2018
          txt2017
          txt2016
          txt2015
          txt2014
          txt2013
          Ano2013
          Ano2014
          Ano2015
          Ano2016
          Ano2017
          Ano2018
          Ano2019
          Ano2020
          Ano2021
          Ano2022
        }
      }
    }
  }
`;

export default function Timeline() {
  const { loading, error, data } = useQuery(Query);

  const baseUrl = "https://admin.getedu.tec.br/";

  const carousel = useRef();
  const [width, SetWidth] = useState();

  useEffect(() => {
    SetWidth(carousel.current?.scrollWidth - carousel.current?.offsetWidth);
  }, [width, carousel]);
  return (
    <section className={Styles.defaultSection}>
      <div className={Styles.timelineHeader}>
        <img src={TimelineLogo} alt="" />
        <h1>Timeline</h1>
      </div>
      <div className={Styles.timelineDiv}>
      <motion.div
        ref={carousel}
        className={Styles.timelineCarousel}
        whileTap={{ cursor: "grabbing" }}
      >
        <motion.div
          className={Styles.innerCarousel}
          drag="x"
          dragConstraints={{ right: 0, left: -width }}
        >
          <Cards
            background={"red"}
            year={parse(`${data?.timeline.data.attributes.Ano2013}`)}
            text={parse(`${data?.timeline.data.attributes.txt2013}`)}
          />
          <Cards
            background={"yellow"}
            year={parse(`${data?.timeline.data.attributes.Ano2014}`)}
            text={parse(`${data?.timeline.data.attributes.txt2014}`)}
          />
          <Cards
            background="green"
            year={parse(`${data?.timeline.data.attributes.Ano2015}`)}
            text={parse(`${data?.timeline.data.attributes.txt2015}`)}
          />
          <Cards
            background={"blue"}
            year={parse(`${data?.timeline.data.attributes.Ano2016}`)}
            text={parse(`${data?.timeline.data.attributes.txt2016}`)}
          />
          <Cards
            background={"red"}
            year={parse(`${data?.timeline.data.attributes.Ano2017}`)}
            text={parse(`${data?.timeline.data.attributes.txt2017}`)}
          />
          <Cards
            background={"yellow"}
            year={parse(`${data?.timeline.data.attributes.Ano2018}`)}
            text={parse(`${data?.timeline.data.attributes.txt2018}`)}
          />
          <Cards
            background="green"
            year={parse(`${data?.timeline.data.attributes.Ano2019}`)}
            text={parse(`${data?.timeline.data.attributes.txt2019}`)}
          />
          <Cards
            background={"blue"}
            year={parse(`${data?.timeline.data.attributes.Ano2020}`)}
            text={parse(`${data?.timeline.data.attributes.txt2020}`)}
          />
          <Cards
            background={"red"}
            year={parse(`${data?.timeline.data.attributes.Ano2021}`)}
            text={parse(`${data?.timeline.data.attributes.txt2021}`)}
          />
          <Cards
            background={"yellow"}
            year={parse(`${data?.timeline.data.attributes.Ano2022}`)}
            text={parse(`${data?.timeline.data.attributes.txt2022}`)}
          />
        </motion.div>
      </motion.div>
      </div>
    </section>
  );
}
