import React from "react";

import FooterDivisor from "../../assets/footerDivisor.svg";
import DesktopFooterDivisor from "../../assets/desktop/desktopDivisor.svg";
import Instagram from "../../assets/instagram.svg";
import Facebook from "../../assets/facebook.svg";
import YouTube from "../../assets/youtube.svg";
import Mail from "../../assets/mail.svg";
import LinkedIn from "../../assets/linkedin_icon.svg";
import Partner from "../../assets/partner.svg";
import Styles from "../Quem.module.css";

import parse from "html-react-parser";
import { useQuery, gql } from "@apollo/client";

const Query = gql`
  query GetFooter {
    footer {
      data {
        attributes {
          Endereco
          Funcionamento
          Telefone
          NomeDaEmpresa
          CNPJ
          Editorial
        }
      }
    }
  }
`;

export default function Footer() {
  const { load, error, data } = useQuery(Query);

  return (
    <section>
      <img className={Styles.footerDivisor} src={FooterDivisor} />
      <img className={Styles.footerDivisorDesktop} src={DesktopFooterDivisor} />
      <div className={Styles.footer}>
        <div className={Styles.footerOrg}>
          <div className={Styles.social}>
            <div>
              <a href="https://www.instagram.com/getedubrasil/" target="_blank">
                <img src={Instagram} />
              </a>
              <a href="https://web.facebook.com/getedubrasil" target="_blank">
                <img src={Facebook} />
              </a>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <a href="https://www.youtube.com/@getedubrasil" target="_blank">
                <img src={YouTube} alt="" />
              </a>
              <a href="mailto:contato@getedu.com.br">
                <img src={Mail} />
              </a>
              <a
                href="https://www.linkedin.com/company/getedu/"
                target="_blank"
              >
                <img src={LinkedIn} alt="" />
              </a>
            </div>
          </div>

          <div className={Styles.footerInfo}>
            {parse(`${data?.footer.data.attributes.Endereco}`)}
            {parse(`${data?.footer.data.attributes.Funcionamento}`)}
            <p>
              <b>
                <a href={"tel:" + data?.footer.data.attributes.Telefone}>
                  {parse(`${data?.footer.data.attributes.Telefone}`)}
                </a>
              </b>
            </p>
            <p>
              <b>Política de privacidade | Termos legais</b>
            </p>
            {parse(`${data?.footer.data.attributes.Editorial}`)}
          </div>
          <div className={Styles.googlePartner}>
            <p>{parse(`${data?.footer.data.attributes.NomeDaEmpresa}`)}</p>
            <p>
              <b>{parse(`${data?.footer.data.attributes.CNPJ}`)}</b>
            </p>
            <img src={Partner} />
          </div>
        </div>
      </div>
    </section>
  );
}
